
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { logout } from '../../services/auth';
import { FaUser, FaPowerOff, FaTrash } from 'react-icons/fa';
import { IoAddCircle } from 'react-icons/io5';

import { LeftAdds } from '../../components/LeftAdds';
import { RightAdds } from '../../components/RightAdds';
import { ModalAddService } from "../../components/ModalAddService";

import imagem from '../../assets/AreaCliente.jpg';

import api from "../../services/api";

import styles from './areaCliente.module.scss';
import { ModalDeleteService } from "../../components/ModalDeleteService";


type UserProps = {
    c_id: number;
    c_name: string;
}

type UserServicesProps = {
    s_id: string;
    s_name: string;
    scat_name: string;
    s_status: string;

}

const token = localStorage.getItem('jwt');

export function AreaCliente() {
    const [userInfo, setUserInfo] = useState<UserProps[]>([]);
    const [userService, setUserService] = useState<UserServicesProps[]>([]);
    const [id, setID] = useState('');
    const [modalAddServico, setModalAddServico] = useState(false);
    const [modalDeleteServico, setModalDeleteServico] = useState(false);

    const handleAddServico = () => {
        setModalAddServico(true);
    }

    const handleDeleteServico = () => {
        setModalDeleteServico(true);
    }

    useEffect(() => { 
    
        async function loadUser() {
    
          const response = await api.get(`usuario/usuario?id=${token}`);
    
          setUserInfo(response.data);    
    
        }
    
        loadUser();
    
    }, []);

    useEffect(() => { 

        async function loadUserServices() {
    
            const response = await api.get(`usuario/userservices?id=${token}`);
      
            setUserService(response.data);    
      
        }
      
        loadUserServices();

    }, []);

    return (
     <>
        {modalAddServico && <ModalAddService open={modalAddServico} close={() => setModalAddServico(false)} id={token} />}
        {modalDeleteServico && <ModalDeleteService open={modalDeleteServico} close={() => setModalDeleteServico(false)} id={id} />}
        <div className="container">
            <section id={styles.areaCliente}>
                <LeftAdds />
                    <div className={styles.content}>
                        <img src={imagem} alt="Área do Cliente" width="100%" />
                        <div className={styles.AreaClienteContent}>
                            <h1>Área do Cliente</h1>
                            <div className={styles.Header}>
                                {userInfo.map(user => (
                                    <span key={user.c_id}><FaUser size={18} color="#2e3192" />&nbsp; Olá, {user.c_name}</span>
                                ))}
                                <Link to="/" onClick={logout}><FaPowerOff size={18} color="#2e3192" />&nbsp; Sair</Link>
                            </div>
                            <div className={styles.Servicos}>
                                <h2>Serviços</h2>
                               
                                    {userService.map(user => (
                                        <div className={styles.service}>
                                            <div className={styles.serviceInfo} key={user.s_id}>
                                                <span className={styles.FirstSpan}>{user.s_name}{user.s_status === '0' ? <p>(Aguardando Liberação)</p> : ''}</span>
                                                <span>{user.scat_name}</span>
                                                <span onClick={handleDeleteServico} onMouseDown={() => setID(user.s_id)}>
                                                    <FaTrash size={18} color="#2e3192" />&nbsp; Excluir
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                    {userService.length === 0 && <p>Não foi encontrado nenhum serviço.</p>}
                                
                                <span onClick={handleAddServico}><IoAddCircle size={18} color="#2e3192" />&nbsp; Add Serviço</span>
                            </div>
                       </div>
                    </div>
                <RightAdds />
            </section>
        </div>
     </>   
     
    );
}