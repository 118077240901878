import { useEffect, useState, Fragment } from 'react';
import { LeftAdds } from '../../components/LeftAdds';
import { BannerPrincipal } from '../../components/BannerPrincipal';
import { BannerSecundario } from '../../components/BannerSecundario';
import { Onibus } from '../../components/Onibus';
import { ParceirosSlide } from '../../components/ParceirosSlide';
import { MiddleAdds } from '../../components/MiddleAdds';
import { MobileAdds } from '../../components/MobileAdds';
import { BannerWebRadio } from '../../components/BannerWebRadio';
import { RightAdds } from '../../components/RightAdds';

import api from '../../services/api';

import styles from './home.module.scss';

type VideoProps = {
    v_id: number;
    v_title: string;
    v_youtube: string;
    v_description: string;
}


export function Home() {
    const [videos, setVideos] = useState<VideoProps[]>([]);
    
    useEffect(() => {

        async function loadVideo() {

            const response = await api.get('home/videos');
      
            setVideos(response.data);
          }
      
          loadVideo();

    }, []);

    return (
        <main>
            <div className="container">
                <section id={styles.home}>
                    <LeftAdds /> 
                        <div className={styles.content}>
                            <BannerPrincipal />
                            <BannerSecundario />
                            <div className={styles.videoDestaque}>
                                <h1>Videos em destaques</h1>
                                {videos.map(video => (
                                    <Fragment key={video.v_id}>
                                        <iframe width="100%" height="315" src={`https://www.youtube.com/embed/${video.v_youtube}`} frameBorder="0" title={video.v_title}></iframe>
                                        <p>{video.v_description}</p>
                                    </Fragment>
                                ))}
                            </div>
                            <Onibus />
                            <ParceirosSlide />
                            <div className={styles.middleAdds}>
                                <MiddleAdds />
                            </div>
                            <div className={styles.mobileAdds}>
                                <MobileAdds />
                            </div>
                            <BannerWebRadio />
                        </div>
                    <RightAdds />
                </section>
            </div>
        </main>
    );
}