import { useEffect, useState } from "react";

import { BannerPrincipal } from "../../components/BannerPrincipal";
import { LeftAdds } from "../../components/LeftAdds";
import { RightAdds } from "../../components/RightAdds";
import api from "../../services/api";

import styles from './regras.module.scss';

type PageProps = {
  p_id: number;
  p_text: string;
}

export function Regras() {
  const [regras, setRegras] = useState<PageProps[]>([]);

  useEffect(() => {

    async function loadContent() {

      const response = await api.get('regras/regras');
  
      setRegras(response.data);

    }
  
      loadContent();

  }, []);

  return (
    <div className="container">
      <section id={styles.regras}>
        <LeftAdds />
          <div className={styles.content}>
            <BannerPrincipal />
            <div className={styles.regrasContent}>
              <h1>Regras</h1>
              {regras.map(content => (
                <div key={content.p_id} dangerouslySetInnerHTML={{ __html: content.p_text}}></div>
              ))}
            </div>
          </div>
        <RightAdds />
      </section>
    </div>
  );
}