import { useEffect, useState } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css';
import styles from './styles.module.scss';

import api from '../../services/api';

type BusProps = {
    b_id: number;
    b_name: string;
    b_link: string;
    b_image: string;
}

export function Onibus(){
    const [onibus, setOnibus] = useState<BusProps[]>([]);
    SwiperCore.use([Autoplay]);

    useEffect(() => {

        async function loadBus() {

            const response = await api.get('home/onibus');
      
            setOnibus(response.data);
          }
      
          loadBus();

    }, []);

    return (
        <div className={styles.HorarioOnibus}>
            <h1>Link de Horário de Ônibus</h1>
            <Swiper 
                className={styles.slideOnibus}
                breakpoints={{
                    992: {
                        slidesPerView: 4
                    },
                    991: {
                        width: 200,
                        slidesPerView: 1
                    },
                }}
                autoplay
            >
                {onibus.map(bus => (
                    <SwiperSlide key={bus.b_id}>
                        <div className={styles.slide}>
                            <a href={bus.b_link} title={bus.b_name} target="_blank" rel="noreferrer">
                                <img src={`http://www.itaclassificados.com.br/arquivos/onibus/${bus.b_image}`} alt={bus.b_name} width="100%" />   
                            </a>
                        </div>
                    </SwiperSlide>
                ))} 
            </Swiper>
        </div>
    );
}