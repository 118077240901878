import { useState } from 'react';
import { useHistory } from "react-router-dom";

import { removeAccents } from '../../services/utils';

import styles from './styles.module.scss';


export function Search() {
  const [data, setData] = useState('');
  let history = useHistory();

  function SearchData(e: any) {
    
    e.preventDefault();
    
    history.push(`/telefones/${removeAccents(data)}`);

  }

  return (
      <div className={styles.listaTelefonia}>
        <form>
            <label htmlFor="lista">
              Lista Telefônica
            </label>
            <input type="text" placeholder="Busca telefônia" onChange={(e) => setData(e.target.value)} value={data} name={data} />
            <button type="submit" onClick={SearchData}>Busca</button>
        </form>
      </div>
    
  );
}