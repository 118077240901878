import { useEffect, useState } from 'react';
import { FaEdit, FaList, FaCheck } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';


import styles from './styles.module.scss';
import api from '../../services/api';

type ServicesProps = {
  scat_id: number;
  scat_name: string;
}

export function ModalAddService(props: any){
 
  const [servicesMenu, setServicesMenu] = useState<ServicesProps[]>([]);
  const [nome, setNome] = useState('');
  const [categoria, setCategoria] = useState('');
  const [descricao, setDescricao] = useState('');
  const [out, setOut] = useState(true);
  const [successMsg, setSuccessMsg] = useState(false);

  async function CadService(e : React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      
      const data = {
        "id": props.id, 
        "nome": nome,
        "categoria": categoria,
        "descricao": descricao
      }

      await api.post('areacliente/addservico', data);

      setSuccessMsg(true);
      setNome('');
      setCategoria('');
      setDescricao('');

      setTimeout(function(){ setSuccessMsg(false); window.location.reload();}, 3000);

      

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {

    async function loadServicesMenu() {

        const response = await api.get('servicos/servicoscategorias');
  
        setServicesMenu(response.data);
    }
  
    loadServicesMenu();

  }, []);



  return (
    <>
      <div className={props.open ?  `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <GrClose size={30} color="#00a651" className={styles.closeIcon} onClick={props.close}  />
            <p>Cadastre aqui o seu serviço e aguarde a moderação do itaclassificados aprova-lo.</p>   
            <form onSubmit={CadService}>
              <div>
                <input type="text" placeholder="Nome de Serviço" name="servico" onChange={(e) => setNome(e.target.value)} value={nome} />
                <label><FaEdit size={18} color="#00a651" /></label>
              </div>
              <div>
                <select name="categoria" onMouseLeave={() => setOut(false)} className={out === false ? `${styles.Focus}` : ``} onChange={(e) => setCategoria(e.target.value)}>
                  <option value="">Categoria</option>
                  {servicesMenu.map(service => (
                    <option value={service.scat_id} key={service.scat_id}>{service.scat_name} - Grátis</option>
                  ))}
                </select>
                <label><FaList size={18} color="#00a651" /></label>
              </div>
              <div>
                <textarea name="descricao" placeholder="Descrição" onChange={(e) => setDescricao(e.target.value)} value={descricao}></textarea>
              </div>
              <button type="submit">Cadastrar Serviço</button>
            </form>
            {successMsg && <div className={styles.sucesso}><FaCheck color="#00a651" size={18} />&nbsp; Serviço cadastrado com sucesso!</div> }
          </div>
        </div>
      </div>
    </>
  );
}