import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { access } from "../../services/auth";
import { FaLock, FaUser } from 'react-icons/fa';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { GrClose } from 'react-icons/gr';
import api from '../../services/api';

import styles from '../ModalCadastro/styles.module.scss';


export function ModalAcessarConta(props: any){
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [successMsg, setSuccessMsg] = useState(false);
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(false);
  let history = useHistory();

  const togglePassWord = () => {
    setType(type === 'text' ? 'password' : 'text');
    setIcon(icon === true ? false : true);
  }

  async function UserLogin(e : React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const username = login;
    const password =  senha;

    try {

      const response = await api.post('modal/login', {login: username, senha: password});


      if(response.data === "Login ou senha inválidos."){
           
        setSuccessMsg(true);

        setTimeout(function(){ setSuccessMsg(false); }, 10000);
        
        history.push('/');

      }else {
        access(response.data);
        history.push('/area-cliente'); 
        window.location.reload();
      }
      

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className={props.open ?  `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <GrClose size={30} color="#00a651" className={styles.closeIcon} onClick={props.close}  />
            <p>Acesse nossas páginas de acesso exclusivos para clientes.</p>   
            <form onSubmit={UserLogin}>
              <div>
                <input type="text" placeholder="E-mail ou Login" name="login" onChange={(e) => setLogin(e.target.value)} autoComplete="username" />
                <label><FaUser size={18} color="#00a651" /></label>
              </div>
              <div>
                <input type={type} placeholder="Senha" name="senha" onChange={(e) => setSenha(e.target.value)} autoComplete="current-password" />
                <label><FaLock size={18} color="#00a651" /></label>
                <label className={styles.TogglePassword}>
                  {
                    icon ? <AiFillEye size={25} color="#00a651" onClick={togglePassWord} /> :  <AiFillEyeInvisible size={25} color="#00a651" onClick={togglePassWord} />
                  }
                </label>
              </div>
              <button type="submit">Acessar</button>
            </form>
            {successMsg && <div className={styles.sucesso}>Login ou senha inválidos.</div> }
          </div>
        </div>
      </div>
    </>
  );
}