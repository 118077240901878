import { useState } from "react";
import InputMask from 'react-input-mask';
import { FaUser, FaMapMarkerAlt, FaClipboardList, FaCheck } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { ImPhone } from "react-icons/im";
import { BannerPrincipal } from "../../components/BannerPrincipal";
import { LeftAdds } from "../../components/LeftAdds";
import { RightAdds } from "../../components/RightAdds";

import styles from './faleconosco.module.scss';
import api from "../../services/api";

export function FaleConosco() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [endereco, setEndereco] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [telefone, setTelefone] = useState('');
  const [assunto, setAssunto] = useState('');
  const [msg, setMSG] = useState('');
  const [out, setOut] = useState(true);
  const [successMsg, setSuccessMsg] = useState(false);

  async function SendFaleConoscoMSG(e : React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      
      const data = {
        "nome": nome,
        "email": email,
        "endereco": endereco,
        "bairro": bairro,
        "cidade": cidade,
        "estado": estado,
        "telefone": telefone,
        "assunto": assunto,
        "mensagem": msg

      }

      await api.post('faleconosco/faleconosco', data);

      setSuccessMsg(true);
      setNome('');
      setEmail('');
      setEndereco('');
      setCidade('');
      setBairro('');
      setEstado('');
      setTelefone('');
      setAssunto('');
      setMSG('');

      setTimeout(function(){ setSuccessMsg(false); }, 3000);

    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <div className="container">
      <section id={styles.faleconosco}>
        <LeftAdds />
          <div className={styles.content}>
            <BannerPrincipal />
            <div className={styles.faleconoscoContent}>
              <h1>Fale Conosco</h1>
              <p>Preencha os campos abaixo para entrar em contato conosco:</p>
              <form onSubmit={SendFaleConoscoMSG}>
                <div>
                  <input type="text" placeholder="Nome" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} />
                  <label><FaUser size={18} color="#00a651" /></label>
                </div>
                <div>
                  <input type="email" placeholder="E-mail" name="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                  <label><GrMail size={18} color="#00a651" /></label>
                </div>
                <div>
                  <input type="text" placeholder="Endereço" name="endereco" onChange={(e) => setEndereco(e.target.value)} value={endereco} />
                  <label><FaMapMarkerAlt size={18} color="#00a651" /></label>
                </div>
                <div>
                  <input type="text" placeholder="Bairro" name="bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} />
                  <label><FaMapMarkerAlt size={18} color="#00a651" /></label>
                </div>
                <div>
                  <input type="text" placeholder="Cidade" name="cidade" onChange={(e) => setCidade(e.target.value)} value={cidade} />
                  <label><FaMapMarkerAlt size={18} color="#00a651" /></label>
                </div>
                <div>
                    <select onMouseLeave={() => setOut(false)} className={out === false ? `${styles.Focus}` : ``} name="estado" onChange={(e) => setEstado(e.target.value)} value={estado}>
                      <option value="">Estado</option>
                      <option value="AC">Acre</option>
                      <option value="AL">Alagoas</option>
                      <option value="AP">Amapá</option>
                      <option value="AM">Amazonas</option>
                      <option value="BA">Bahia</option>
                      <option value="CE">Ceará</option>
                      <option value="DF">Distrito Federal</option>
                      <option value="ES">Espírito Santo</option>
                      <option value="GO">Goiás</option>
                      <option value="MA">Maranhão</option>
                      <option value="MT">Mato Grosso</option>
                      <option value="MS">Mato Grosso do Sul</option>
                      <option value="MG">Minas Gerais</option>
                      <option value="PA">Pará</option>
                      <option value="PB">Paraíba</option>
                      <option value="PR">Paraná</option>
                      <option value="PE">Pernambuco</option>
                      <option value="PI">Piauí</option>
                      <option value="RJ">Rio de Janeiro</option>
                      <option value="RN">Rio Grande do Norte</option>
                      <option value="RS">Rio Grande do Sul</option>
                      <option value="RO">Rondônia</option>
                      <option value="RR">Roraima</option>
                      <option value="SC">Santa Catarina</option>
                      <option value="SP">São Paulo</option>
                      <option value="SE">Sergipe</option>
                      <option value="TO">Tocantins</option>
                    </select>
                    <label><FaMapMarkerAlt size={18} color="#00a651" /></label>
                </div>
                <div>
                  <InputMask mask="(99) 99999-9999" type="tel" placeholder="Telefone" name="telefone" onChange={(e) => setTelefone(e.target.value)} value={telefone} />
                  <label><ImPhone size={18} color="#00a651" /></label>
                </div>
                <div>
                  <input type="text" placeholder="Assunto" name="assunto" onChange={(e) => setAssunto(e.target.value)} value={assunto} />
                  <label><FaClipboardList size={18} color="#00a651" /></label>
                </div>
                <div>
                  <textarea name="mensagem" placeholder="Mensagem" onChange={(e) => setMSG(e.target.value)} value={msg}></textarea> 
                </div>
                <button type="submit">Enviar Mensagem</button>
              </form>
              {successMsg && <div className={styles.sucesso}><FaCheck color="#00a651" size={18} />&nbsp; Mensagem enviada com sucesso!</div> }
              <p className={styles.footer}>
                Ou entre em contato pelos telefone abaixo:<br />
 	              (77) 98101-6284 - OI - Marcos CD
              </p>
            </div>
          </div>
        <RightAdds />
      </section>
    </div>
  );
}