import { useState } from 'react';
import { GrClose, GrMail } from 'react-icons/gr';
import api from '../../services/api';

import styles from '../ModalCadastro/styles.module.scss';

export function ModalEsqueciSenha(props: any){
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState(false);

  async function SendEsqueciSenha(e : React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      
      const data = {
        "email": email
      }

      const response = await api.post('modal/esquecisenha', data);

      setMsg(response.data);

      setSuccessMsg(true);
      setEmail('');

      setTimeout(function(){ setSuccessMsg(false); }, 10000);

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className={props.open ?  `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <GrClose size={30} color="#00a651" className={styles.closeIcon} onClick={props.close}  />
            <p>Caso tenha esquecido a sua senha de acesso ao Itaclassificados, 
              por favor preencha o campo abaixo com seu e-mail de cadastro, que logo em 
              seguida você receberá em seu e-mail com sua senha.</p>   
            <form onSubmit={SendEsqueciSenha}>
              <div>
                <input type="email" placeholder="E-mail" name="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                <label><GrMail size={18} color="#00a651" /></label>
              </div>
              <button type="submit">Enviar senha</button>
            </form>
            {successMsg && <div className={styles.sucesso}>{msg}</div> }
          </div>
        </div>
      </div>
    </>
  );
}