import { Route, Redirect } from 'react-router-dom';
import { isLogin } from "../../services/auth";


export const PrivateRoute = ({component: Component, ...rest }: any) => {
  return (

      <Route {...rest} render={props => (
          isLogin() ?
              <Component {...props} />
          : <Redirect to="/" />
      )} />
  );
};