import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ImPhone } from 'react-icons/im';
import { MdSmartphone } from 'react-icons/md';
import { GrMail } from 'react-icons/gr';

import { BannerPrincipal } from "../../components/BannerPrincipal";
import { LeftAdds } from "../../components/LeftAdds";
import { RightAdds } from "../../components/RightAdds";

import ReactPaginate from 'react-paginate';

import api from "../../services/api";

import styles from '../Servicos/servicos.module.scss';

type ServicesProps = {
  s_id: number;
  s_name: string;
  s_description: string;
  c_telefone: string;
  c_celular1: string;
  c_email: string;

}

type ServiceParams = {
  name: string;
};

export function ServicosSearch() {
  const { name } = useParams<ServiceParams>();
  const [services, setServices] = useState<ServicesProps[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const limit = 4;
  const offset = currentPage * limit;
  const pageCount = Math.ceil(services.length / limit);

  const handlePageClick = (selectedPage: any) => {
    let page = selectedPage.selected;
    setCurrentPage(page)
  }

  useEffect(() => { 
    
    async function loadContent() {

      const response = await api.get(`servicos/servicosSearch?name=${name}`);

      setServices(response.data);    

    }

    loadContent();

  }, [name]);

  return (
    <>
      <div className="container">
        <section id={styles.servicos}>
          <LeftAdds />
            <div className={styles.content}>
                <BannerPrincipal />
                <div className={styles.ServicosContent}>
                  <h1>Categoria</h1>
                  {services.slice(offset, offset + limit).map(content => (
                    
                    <div key={content.s_id}>
                      <span>{content.s_name}</span>
                      <div dangerouslySetInnerHTML={{ __html: content.s_description}} className={styles.description} />
                      <div className={styles.contato}>
                        {content.c_telefone && (
                          <span>
                            <ImPhone size={18} color="##2e3192" />&nbsp; 
                            {
                              "("+content.c_telefone.slice(0,2)+") "+
                              content.c_telefone.slice(2,6)+"-"+
                              content.c_telefone.slice(6,10)
                            }
                          </span>
                        )}
                        {content.c_celular1 && (
                          <span>
                          <MdSmartphone size={18} color="##2e3192" />&nbsp; 
                            {
                              "("+content.c_celular1.slice(0,2)+") "+
                              content.c_celular1.slice(2,6)+"-"+
                              content.c_celular1.slice(6,10)
                            }
                          </span>
                        )}
                      
                        <span><GrMail size={18} color="##2e3192" />&nbsp; {content.c_email}</span>
                        
                      </div>
                    </div>
                  ))}
                </div>
                
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                />

            </div>
          <RightAdds />
        </section>
      </div>
    </>
  );
}