import { useState } from "react";
import { FaTrashAlt, FaCheck } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import api from "../../services/api";

import styles from './styles.module.scss';

export function ModalDeleteService(props: any){
    const [successMsg, setSuccessMsg] = useState(false);

    async function handleDeleteService(e : React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        try {

            const data = {
                "id": props.id
            }

            await api.post('areacliente/deleteservico', data);
                
            setSuccessMsg(true);

            setTimeout(function(){ setSuccessMsg(false); window.location.reload();}, 3000);
           
      
        } catch (error) {
            console.log(error);
        }
        
    }


    return (
        <>
            <div className={props.open ?  `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <GrClose size={30} color="#00a651" className={styles.closeIcon} onClick={props.close}  />
                        <br />
                        <p>
                            <FaTrashAlt size={40} color="#00a651" />
                            <br /><br />
                            Voce tem certeza? 
                        </p>   
                        <p>
                            Tem certeza que deseja deletar este serviço.
                        </p>
                        <button className="cancelar" onClick={props.close}>Cancelar</button>
                        <button className="deletar" onClick={handleDeleteService}>Deletar</button>
                        {successMsg && <div className={styles.sucesso}><FaCheck color="#00a651" size={18} />&nbsp; Serviço deletado com sucesso!</div> }
                    </div>
                </div>
            </div>
        </>
    );
}