import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ImPhone } from 'react-icons/im';

import { BannerPrincipal } from "../../components/BannerPrincipal";
import { LeftAdds } from "../../components/LeftAdds";
import { RightAdds } from "../../components/RightAdds";

import ReactPaginate from 'react-paginate';

import api from "../../services/api";

import styles from './telefones.module.scss';

type TelefonesProps = {
  pn_id: number;
  pn_name: string;
  pn_endereco: string;
  pn_telefone: string;
}

type TelefonesParams = {
  name: string;
};

export function Telefones(props: any) {
  const { name } = useParams<TelefonesParams>();
  const [telefones, setTelefones] = useState<TelefonesProps[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const limit = 4;
  const offset = currentPage * limit;
  const pageCount = Math.ceil(telefones.length / limit);

  const handlePageClick = (selectedPage: any) => {
    let page = selectedPage.selected;
    setCurrentPage(page)
  }

  useEffect(() => { 
    
    async function loadContent() {

      const response = await api.get(`telefones/telefones?name=${name}`);

      setTelefones(response.data);    

    }

    loadContent();

  }, [name]);

  return (
    <>
      <div className="container">
        <section id={styles.servicos}>
          <LeftAdds />
            <div className={styles.content}>
                <BannerPrincipal />
                <div className={styles.ServicosContent}>
                  <h1>Telefones Úteis</h1>
                  {telefones.slice(offset, offset + limit).map(content => (
                    
                    <div key={content.pn_id}>
                      <span>{content.pn_name}</span>
                      <div dangerouslySetInnerHTML={{ __html: content.pn_endereco}} className={styles.description} />
                      <div className={styles.contato}>
                        {content.pn_telefone && (
                          <span>
                            <ImPhone size={18} color="##2e3192" />&nbsp; 
                            {content.pn_telefone}
                          </span>
                        )} 
                      </div>
                    </div>
                  ))}
                </div>
                
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                />

            </div>
          <RightAdds />
        </section>
      </div>
    </>
  );
}