import { useEffect, useState } from 'react';
import { FaDesktop, FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa';

import api from '../../services/api';

import styles from './styles.module.scss';

type LeftAddsProps = {
    b_id: number;
    b_title: string;
    b_image: string;
    b_image2: string;
    b_description: string;
    b_link: string;
    b_site: string;
    b_facebook: string;
    b_instagram: string;
    b_whatsapp: string;
    b_youtube: string;
}

export function LeftAdds() {
    const [adds, setAdds] = useState<LeftAddsProps[]>([]);

    useEffect(() => {

        async function loadLeftAdds() {

            const response = await api.get('home/leftadds');
      
            setAdds(response.data);
        }
      
          loadLeftAdds();

    }, []);

    return (
        <aside className={styles.leftAdds}>
            {adds.map(add => (
                <div key={add.b_id}>
                    {add.b_link === '' ?
                        <>
                            <img src={`http://www.itaclassificados.com.br/arquivos/banners/${add.b_image}`} alt={add.b_title} width="100%" />
                            <ul className={styles.listSocial}>
                                {add.b_site !== '' ? 
                                    <li>
                                        <a href={add.b_site} target="_blank" rel="noopener noreferrer">
                                            <FaDesktop color="#000000" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {add.b_facebook !== '' ? 
                                    <li>
                                        <a href={`https://www.facebook.com/${add.b_facebook}`} target="_blank" rel="noopener noreferrer">
                                            <FaFacebook color="#1a73e3" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {add.b_instagram !== '' ? 
                                    <li>
                                        <a href={`https://www.instagram.com/${add.b_instagram}`} target="_blank" rel="noopener noreferrer">
                                            <FaInstagram color="#b833b4" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {add.b_whatsapp !== '' ? 
                                    <li>
                                        <a href={`https://api.whatsapp.com/send?phone=5577${add.b_whatsapp}`} target="_blank" rel="noopener noreferrer">
                                            <FaWhatsapp color="#4bc657" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {add.b_youtube !== '' ? 
                                    <li>
                                        <a href={`https://www.youtube.com/watch?v=${add.b_youtube}`} target="_blank" rel="noopener noreferrer">
                                            <FaYoutube color="#cc0100" size={20} />
                                        </a>
                                    </li>
                                : ''}
                            </ul>
                        </>
                        :
                        <>
                            <a href={add.b_link} title={add.b_title} target="_blank" rel="noreferrer">
                                <img src={`http://www.itaclassificados.com.br/arquivos/banners/${add.b_image}`} alt={add.b_title} width="100%" />
                            </a>
                            <ul className={styles.listSocial}>
                                {add.b_site !== '' ? 
                                    <li>
                                        <a href={add.b_site} target="_blank" rel="noopener noreferrer">
                                            <FaDesktop color="#000000" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {add.b_facebook !== '' ? 
                                    <li>
                                        <a href={`https://www.facebook.com/${add.b_facebook}`} target="_blank" rel="noopener noreferrer">
                                            <FaFacebook color="#1a73e3" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {add.b_instagram !== '' ? 
                                    <li>
                                        <a href={`https://www.instagram.com/${add.b_instagram}`} target="_blank" rel="noopener noreferrer">
                                            <FaInstagram color="#b833b4" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {add.b_whatsapp !== '' ? 
                                    <li>
                                        <a href={`https://api.whatsapp.com/send?phone=5577${add.b_whatsapp}`} target="_blank" rel="noopener noreferrer">
                                            <FaWhatsapp color="#4bc657" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {add.b_youtube !== '' ? 
                                    <li>
                                        <a href={`https://www.youtube.com/watch?v=${add.b_youtube}`} target="_blank" rel="noopener noreferrer">
                                            <FaYoutube color="#cc0100" size={20} />
                                        </a>
                                    </li>
                                : ''}
                            </ul>
                        </>
                    }
                </div>
            ))}
        </aside>
    );
}