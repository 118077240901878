import { Routes } from './routes';

import './styles/global.scss';

function App() {
  return (
   <>
    <Routes />
   </>
  );
}

export default App;
