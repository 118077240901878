import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { FaSearch, FaUser, FaUserPlus, FaLock, FaUnlockAlt, FaWhatsapp } from 'react-icons/fa';
import { GoTriangleDown } from 'react-icons/go';
import api from '../../services/api';
import { ModalAcessarConta } from '../ModalAcessarConta';
import { ModalCadastro } from '../ModalCadastro';
import { ModalEsqueciSenha } from '../ModalEsqueciSenha';

import { removeAccents } from '../../services/utils';

import styles from './styles.module.scss';

type VisitasProps = {
    id: number;
    visits: number;
}

const token = localStorage.getItem('jwt');

export function TopBar(){
    const [subMenu, setSubmenu] = useState(false);
    const [search, setSearch] = useState(false);
    const [modalCadastro, setModalCadastro] = useState(false);
    const [modalEsqueciSenha, setModalEsqueciSenha] = useState(false);
    const [modalAcessoConta, setModalAcessoConta] = useState(false);
    const [visitas, setVisitas] = useState<VisitasProps[]>([]);
    const [data, setData] = useState('');
    let history = useHistory();

    const showSubMenu = () => {
        setSubmenu(!subMenu);
    }

    const hideSubMenu = () => {
        setSubmenu(false);
    }

    const showSearch = () => {
        setSearch(!search);
    }

    const handleCadastro = () => {
        setModalCadastro(true);
    }

    const handleEsqueciSenha = () => {
        setModalEsqueciSenha(true);
    }

    const handleAcessoConta = () => {
        setModalAcessoConta(true);
    }

    const SearchData = (e: any) => {
    
        e.preventDefault();

        setSearch(!search);

        setData('');
        
        history.push(`/servico/${removeAccents(data)}`);
    
    }

    

    useEffect(() => {

        async function loadVisit() {

            const response = await api.get('visitantes/count_visits');
      
            setVisitas(response.data);
          }
      
          loadVisit();

    }, []);

    const NumVisitas = visitas.map(visit => visit.visits);

    const countVisitas = `${NumVisitas}`;

    return (
       <>
        {modalCadastro && <ModalCadastro open={modalCadastro} close={() => setModalCadastro(false)} />}
        {modalEsqueciSenha && <ModalEsqueciSenha open={modalEsqueciSenha} close={() => setModalEsqueciSenha(false)} />}
        {modalAcessoConta && <ModalAcessarConta open={modalAcessoConta} close={() => setModalAcessoConta(false)} />}
            <div className={styles.iframeWebRadio}>
                <iframe src="https://player.maxcast.com.br/webradioitapetinga" width="100%" height="60" title="WebRadio Itapetinga" frameBorder="0" scrolling="no"></iframe>
                <div className="container">
                    <div className={styles.infoRadio}>
                        <span>Web radio itapetinga.com de volta aos anos 80.</span>
                        <span>
                            <a href="https://api.whatsapp.com/send?phone=5577981016284" target="_blank" rel="noreferrer">
                                <FaWhatsapp size={18} color="#2e3192" /> (77) 98101-6284 - OI - Marcos CD
                            </a>
                        </span>
                        <span>Clique no player acima para ouvir.</span>
                    </div>
                </div>
            </div>
            <div className={styles.topBar}>
                <div className="container">
                    <div className={styles.topBarContent}>
                        <span>
                            Seja bem vindo, você é o visitante de número: 
                            {` ${countVisitas.slice(0,1)}.${countVisitas.slice(1,4)}.${countVisitas.slice(4,7)} `}
                        </span>
                        <ul>
                            <li onMouseEnter={hideSubMenu}>
                                <FaSearch size={18} color="#2e3192" onClick={showSearch} />
                            </li>
                            {search && 
                                <li>
                                    <form>
                                        <input type="text" placeholder="Buscar serviço" onChange={(e) => setData(e.target.value)} value={data} name={data} />
                                        <button type="submit" onClick={SearchData}>Buscar</button>
                                    </form>
                                </li>
                            }
                            <li onMouseEnter={showSubMenu} className={styles.hideMobile}>
                                <FaUser size={18} color="#2e3192" /> Área do cliente <GoTriangleDown size={16} color="#2e3192" />
                                {subMenu &&
                                    <ul className={styles.submenu}>
                                        <li onClick={handleCadastro}>
                                            <FaUserPlus size={18} color="#2e3192" /> Cadastre-se
                                        </li>
                                        {
                                            token ?
                                            <>
                                                <li>
                                                    <a href="/area-cliente">
                                                        <FaUser size={18} color="#2e3192" /> Área do cliente
                                                    </a>
                                                </li>
                                            </> :
                                            <>  
                                                <li onClick={handleAcessoConta}>
                                                    <FaLock size={18} color="#2e3192" /> Acessar
                                                </li>
                                            </>
                                        }
                                        <li onClick={handleEsqueciSenha}>
                                            <FaUnlockAlt size={18} color="#2e3192" /> Esqueci a senha
                                        </li>
                                    </ul>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
       </>
    );
}