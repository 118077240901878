import { useEffect, useState } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaDesktop, FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa';

import 'swiper/swiper-bundle.css';
import api from '../../services/api';
import styles from './styles.module.scss';

type ParceirosProps = {
    p_id: number;
    p_title: string;
    p_image: string;
    p_image2: string;
    p_description: string;
    p_link: string;
    p_site: string;
    p_facebook: string;
    p_instagram: string;
    p_whatsapp: string;
    p_youtube: string;
}

export function ParceirosSlide(){
    const [parceiros, setParceiros] = useState<ParceirosProps[]>([]);
    SwiperCore.use([Autoplay]);

    useEffect(() => {

        async function loadParceiros() {

            const response = await api.get('home/parceiros');
      
            setParceiros(response.data);
          }
      
          loadParceiros();

    }, []);

    return (
        <div className={styles.Parceiros}>
            <h1>Link de Parceiros</h1>
            <Swiper
                className={styles.slideParceiros}
                breakpoints={{
                    992: {
                        slidesPerView: 4
                    },
                    991: {
                        slidesPerView: 2
                    },
                }}
                autoplay
                
            >
                {parceiros.map(parceiro => (
                    <SwiperSlide key={parceiro.p_id}>
                        <div className={styles.slide}>
                            <a href={parceiro.p_link} title={parceiro.p_title} target="_blank" rel="noreferrer">
                                <img src={`http://www.itaclassificados.com.br/arquivos/parceiros/${parceiro.p_image}`} alt={parceiro.p_title} width="100%" />
                            </a>
                            <ul className={styles.listSocial}>
                                {parceiro.p_site !== '' ? 
                                    <li>
                                        <a href={parceiro.p_site} target="_blank" rel="noopener noreferrer">
                                            <FaDesktop color="#000000" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {parceiro.p_facebook !== '' ? 
                                    <li>
                                        <a href={`https://www.facebook.com/${parceiro.p_facebook}`} target="_blank" rel="noopener noreferrer">
                                            <FaFacebook color="#1a73e3" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {parceiro.p_instagram !== '' ? 
                                    <li>
                                        <a href={`https://www.instagram.com/${parceiro.p_instagram}`} target="_blank" rel="noopener noreferrer">
                                            <FaInstagram color="#b833b4" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {parceiro.p_whatsapp !== '' ? 
                                    <li>
                                        <a href={`https://api.whatsapp.com/send?phone=5577${parceiro.p_whatsapp}`} target="_blank" rel="noopener noreferrer">
                                            <FaWhatsapp color="#4bc657" size={20} />
                                        </a>
                                    </li>
                                : ''}
                                {parceiro.p_youtube !== '' ? 
                                    <li>
                                        <a href={`https://www.youtube.com/watch?v=${parceiro.p_youtube}`} target="_blank" rel="noopener noreferrer">
                                            <FaYoutube color="#cc0100" size={20} />
                                        </a>
                                    </li>
                                : ''}
                            </ul>
                        </div>
                    </SwiperSlide> 
                ))}      
            </Swiper>
        </div>
    );
}