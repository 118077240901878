import { useState } from 'react';
import InputMask from 'react-input-mask';
import { FaUser, FaUserPlus, FaLock } from 'react-icons/fa';
import { ImLocation, ImPhone } from 'react-icons/im';
import { MdSmartphone } from 'react-icons/md';
import { GrClose, GrMail } from 'react-icons/gr';

import styles from './styles.module.scss';
import api from '../../services/api';

export function ModalCadastro(props: any){
 
  const [out, setOut] = useState(true);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [endereco, setEndereco] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular1, setCelular1] = useState('');
  const [celular2, setCelular2] = useState('');
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [successMsg, setSuccessMsg] = useState(false);
  const [msg, setMsg] = useState('');

  async function SendCadastro(e : React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const formatedTelefone = telefone.replace('(','').replace(')','').replace(' ','').replace('-','');
    const formatedCelular1 = celular1.replace('(','').replace(')','').replace(' ','').replace('-','');
    const formatedCelular2 = celular2.replace('(','').replace(')','').replace(' ','').replace('-','');

    try {
      
      const data = {
        "nome": nome,
        "email": email,
        "endereco": endereco,
        "bairro": bairro,
        "cidade": cidade,
        "estado": estado,
        "telefone": formatedTelefone,
        "celular1": formatedCelular1,
        "celular2": formatedCelular2,
        "login": login,
        "senha": senha
      }

      const response = await api.post('modal/cadastro', data);

      setMsg(response.data);
      setSuccessMsg(true);
      setNome('');
      setEmail('');
      setEndereco('');
      setCidade('');
      setBairro('');
      setEstado('');
      setTelefone('');
      setCelular1('');
      setCelular2('');
      setLogin('');
      setSenha('');

      setTimeout(function(){ setSuccessMsg(false); }, 3000);

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className={props.open ?  `${styles.modalBG} ${styles.showModal} ` : `${styles.modalBG}`}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <GrClose size={30} color="#00a651" className={styles.closeIcon} onClick={props.close}  />
            <p>Cadastre-se para poder acessar alguma de nossas paginas de acesso 
            exclussivos para clientes. Ex: Cadastro de Serviços e etc...</p>   
            <form onSubmit={SendCadastro}>
              <div>
                <input type="text" placeholder="Nome Completo" name="nome" onChange={(e) => setNome(e.target.value)} value={nome} />
                <label><FaUser size={18} color="#00a651" /></label>
              </div>
              <div>
                <input type="email" placeholder="E-mail" name="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                <label><GrMail size={18} color="#00a651" /></label>
              </div>
              <div>
                <input type="text" placeholder="Endereço" name="endereco" onChange={(e) => setEndereco(e.target.value)} value={endereco} />
                <label><ImLocation size={18} color="#00a651" /></label>
              </div>
              <div>
                <input type="text" placeholder="Bairro" name="bairro" onChange={(e) => setBairro(e.target.value)} value={bairro} />
                <label><ImLocation size={18} color="#00a651" /></label>
              </div>
              <div>
                <input type="text" placeholder="Cidade" name="cidade" onChange={(e) => setCidade(e.target.value)} value={cidade} />
                <label><ImLocation size={18} color="#00a651" /></label>
              </div>
              <div>
                <select name="estado" onMouseLeave={() => setOut(false)} className={out === false ? `${styles.Focus}` : ``} onChange={(e) => setEstado(e.target.value)}>
                  <option value="">Estado</option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
                <label><ImLocation size={18} color="#00a651" /></label>
              </div>
              <div>
                <InputMask mask="(99) 9999-9999" type="tel" placeholder="Telefone" name="telefone" onChange={(e) => setTelefone(e.target.value)} value={telefone} />
                <label><ImPhone size={18} color="#00a651" /></label>
              </div>
              <div>
                <InputMask mask="(99) 99999-9999" type="tel" placeholder="Celular" name="celular1" onChange={(e) => setCelular1(e.target.value)} value={celular1} />
                <label><MdSmartphone size={18} color="#00a651" /></label>
              </div>
              <div>
                <InputMask mask="(99) 99999-9999" type="tel" placeholder="Celular" name="celular2" onChange={(e) => setCelular2(e.target.value)} value={celular2} />
                <label><MdSmartphone size={18} color="#00a651" /></label>
              </div>
              <div>
                <input type="text" placeholder="Login" name="login" onChange={(e) => setLogin(e.target.value)} autoComplete="username" value={login} />
                <label><FaUserPlus size={18} color="#00a651" /></label>
              </div>
              <div>
                <input type="password" placeholder="Senha" name="senha" onChange={(e) => setSenha(e.target.value)} autoComplete="current-password" maxLength={10} value={senha} />
                <label><FaLock size={18} color="#00a651" /></label>
              </div>
              <button type="submit">Cadastrar</button>
            </form>
            {successMsg && 
              <div className={styles.sucesso}>
                {msg}
              </div> 
            }
            
          </div>
        </div>
      </div>
    </>
  );
}