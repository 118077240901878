import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Header } from './components/Header';
import { Home } from './pages/Home';
import { QuemSomos } from './pages/QuemSomos';
import { Regras } from './pages/Regras';
import { FaleConosco } from './pages/FaleConosco';
import { Servicos } from './pages/Servicos';
import { Telefones } from './pages/Telefones';
import { ServicosSearch } from './pages/ServicosSearch';
import { AreaCliente } from './pages/AreaCliente';
import {PrivateRoute} from './components/PrivateRoute';
import { Footer } from './components/Footer';


export function Routes(){
    
    return(
        <BrowserRouter>
            <Header />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/quem-somos" component={QuemSomos} />
                <Route path="/regras" component={Regras} />
                <Route path="/fale-conosco" component={FaleConosco} />
                <Route path={`/servicos/:name`} component={Servicos} />
                <Route path={`/servico/:name`} component={ServicosSearch} />
                <Route path={`/telefones/:name`} component={Telefones} />
                <PrivateRoute exact path="/area-cliente" component={AreaCliente}  />
            </Switch>
            <Footer />
        </BrowserRouter>
    );
}