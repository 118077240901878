import { useEffect, useState } from "react";
import { FaLock, FaUnlockAlt, FaUser, FaUserPlus } from "react-icons/fa";
import { GoTriangleDown } from "react-icons/go";
import { Link } from "react-router-dom";

import { TopBar } from "../TopBar";
import { ModalCadastro } from "../ModalCadastro";
import { ModalEsqueciSenha } from "../ModalEsqueciSenha";
import { ModalAcessarConta } from "../ModalAcessarConta";

import logo from  '../../assets/logo.png';

import api from "../../services/api";

import '../../styles/menu.scss';
import styles from './styles.module.scss';
import { Search } from "../Search";

type ServicesProps = {
    scat_id: number;
    scat_name: string;
    scat_slug: string;
}

const token = localStorage.getItem('jwt');

export function Header() {

    const [subMenuServices, setSubmenuServices] = useState(false);
    const [subMenuClient, setSubmenuClient] = useState(false);
    const [servicesMenu, setServicesMenu] = useState<ServicesProps[]>([]);
    const [openMobile, setOpenMobile ] = useState(false);
    const [modalCadastro, setModalCadastro] = useState(false);
    const [modalEsqueciSenha, setModalEsqueciSenha] = useState(false);
    const [modalAcessoConta, setModalAcessoConta] = useState(false);

    const showSubMenuServices = () => {
        setSubmenuServices(!subMenuServices);
    }

    const showSubMenuClient = () => {
        setSubmenuClient(!subMenuClient);
    }

    const hideSubMenu = () => {
        setSubmenuServices(false);
        setSubmenuClient(false);
    }

    const handleCadastro = () => {
        setModalCadastro(true);
    }

    const handleEsqueciSenha = () => {
        setModalEsqueciSenha(true);
    }

    const handleAcessoConta = () => {
        setModalAcessoConta(true);
    }

    useEffect(() => {

        async function loadServicesMenu() {

            const response = await api.get('servicos/servicoscategorias');
      
            setServicesMenu(response.data);
          }
      
          loadServicesMenu();

    }, []);

    return (
        <>
        <TopBar />
        {modalCadastro && <ModalCadastro open={modalCadastro} close={() => setModalCadastro(false)} />}
        {modalEsqueciSenha && <ModalEsqueciSenha open={modalEsqueciSenha} close={() => setModalEsqueciSenha(false)} />}
        {modalAcessoConta && <ModalAcessarConta open={modalAcessoConta} close={() => setModalAcessoConta(false)} />}
            <header className={styles.header}>
                <div className="container">
                    <div className={styles.mobile}>
                        <button type="button" onClick={() => setOpenMobile(!openMobile)} className={openMobile ? "hamburger hamburger--stand is-active" : "hamburger hamburger--spin"}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                        <div className={styles.logo}>
                            <a href="/">
                                <img src={logo} alt="ItaClassificados" />
                            </a>
                        </div>
                    </div>
                    <div className={styles.searchServiceMobile}>
                        <Search  />
                    </div>
                    {openMobile && 
                        <div className={styles.menuMobile}>
                            <ul>
                                <li onMouseEnter={hideSubMenu}>
                                    <a href="/">Home</a>
                                </li>
                                <li onMouseEnter={hideSubMenu}>
                                    <a href="/quem-somos">Quem Somos</a>
                                </li>
                                <li onMouseEnter={hideSubMenu}>
                                    <a href="/regras">Regras</a>
                                </li>
                                <li onMouseEnter={showSubMenuServices}>
                                    Serviços <GoTriangleDown size={16} color="#2e3192" />
                                    <ul className={styles.submenu}>
                                        {(subMenuServices &&
                                            <>
                                                {servicesMenu.map(service => (
                                                    <li key={service.scat_id}>
                                                        <a href={`/servicos/${service.scat_slug}`} onClick={hideSubMenu}>
                                                            {service.scat_name}
                                                        </a>
                                                    </li>
                                                ))}
                                            </>   
                                        )}   
                                    </ul>                       
                                </li>
                                <li onMouseEnter={hideSubMenu}>
                                    <a href="/fale-conosco">Fale Conosco</a>
                                </li>
                                <li onMouseEnter={showSubMenuClient}>
                                    <FaUser size={18} color="#2e3192" /> Área do cliente <GoTriangleDown size={16} color="#2e3192" />
                                    {subMenuClient &&
                                        <ul className={styles.submenu}>
                                            <li onClick={handleCadastro}>
                                                <FaUserPlus size={18} color="#2e3192" /> Cadastre-se
                                            </li>
                                            {token ?
                                                <>
                                                    <li>
                                                        <a href="/area-cliente">
                                                            <FaUser size={18} color="#2e3192" /> Área do cliente
                                                        </a>
                                                    </li>
                                                </> :
                                                <>  
                                                    <li onClick={handleAcessoConta}>
                                                        <FaLock size={18} color="#2e3192" /> Acessar
                                                    </li>
                                                </>
                                            }
                                            <li onClick={handleEsqueciSenha}>
                                                <FaUnlockAlt size={18} color="#2e3192" /> Esqueci a senha
                                            </li>
                                        </ul>
                                    }
                                </li>
                            </ul>
                        </div>
                    }
                    <div className={styles.headerMenu}>
                        <div className={styles.logo}>
                            <a href="/">
                                <img src={logo} alt="ItaClassificados" />
                            </a>
                        </div>
                        <div className={styles.headerContent}>
                            <ul>
                                <li onMouseEnter={hideSubMenu}>
                                    <a href="/">Home</a>
                                </li>
                                <li onMouseEnter={hideSubMenu}>
                                    <a href="/quem-somos">Quem Somos</a>
                                </li>
                                <li onMouseEnter={hideSubMenu}>
                                    <a href="/regras">Regras</a>
                                </li>
                                <li onMouseEnter={showSubMenuServices}>
                                    Serviços <GoTriangleDown size={16} color="#2e3192" />
                                    <ul className={styles.submenu}>
                                        {(subMenuServices &&
                                            <>
                                                {servicesMenu.map(service => (
                                                    <li key={service.scat_id}>
                                                        <Link to={`/servicos/${service.scat_slug}`} onClick={hideSubMenu}>
                                                            {service.scat_name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </>   
                                        )}   
                                    </ul>                       
                                </li>
                                <li onMouseEnter={hideSubMenu}>
                                    <a href="/fale-conosco">Fale Conosco</a>
                                </li>
                            </ul>
                            <Search />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}