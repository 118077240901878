export function removeAccents(str: any) {
  let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  let accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  str = str.split('');
  str.forEach((letter: any, index: any) => {
    let i = accents.indexOf(letter);
    if (i !== -1) {
      str[index] = accentsOut[i];
    }
  })
  return str.join('');
}