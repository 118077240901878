import { useEffect, useState } from 'react';

import api from '../../services/api';

import styles from './styles.module.scss';

type BannerProps = {
    b_id: number;
    b_title: string;
    b_link: string;
    b_image: string;
}

export function BannerWebRadio() {
    const [banner, setBanner] = useState<BannerProps[]>([]);

    useEffect(() => {

        async function loadBanner() {

            const response = await api.get('home/webradio');
      
            setBanner(response.data);
          }
      
          loadBanner();

    }, []);


    return (
        <div className={styles.webRadioBanner}>
            <h1>Web Radio Itapetinga</h1>
            {banner.map(banner => (
                <a href={banner.b_link} target="_blank" rel="noreferrer" key={banner.b_id}>
                    <img src={`http://www.itaclassificados.com.br/arquivos/banners/${banner.b_image}`} alt={banner.b_title} />
                </a>
            ))}
        </div>
    );
}