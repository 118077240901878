import { useEffect, useState } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css';
import api from '../../services/api';
import styles from './styles.module.scss';

type BannerProps = {
    b_id: number;
    b_image: string;
}

export function BannerPrincipal(){
    const [banners, setBanners] = useState<BannerProps[]>([]);
    SwiperCore.use([Autoplay]);

    useEffect(() => {

        async function loadMainBanner() {

            const response = await api.get('home/mainbanner');
      
            setBanners(response.data);
          }
      
          loadMainBanner();

    }, []);

    return (
        <Swiper slidesPerView={1} autoplay>
            {banners.map(banner => (
                <SwiperSlide key={banner.b_id}>
                    <div className={styles.slide} style={{ backgroundImage: `url('http://www.itaclassificados.com.br/arquivos/banners/${banner.b_image}')`}}></div>
                </SwiperSlide> 
            ))}  
        </Swiper>
    );
}