import { useEffect, useState } from 'react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css';
import api from '../../services/api';

import styles from './styles.module.scss';

type BannerProps = {
    h_id: number;
    h_title: string;
    h_description: string;
    h_image: string;
}

export function BannerSecundario(){
    const [banners, setBanners] = useState<BannerProps[]>([]);
    SwiperCore.use([Pagination, Autoplay]);

    useEffect(() => {

        async function loadSecondaryBanner() {

            const response = await api.get('home/secondarybanner');
      
            setBanners(response.data);
          }
      
          loadSecondaryBanner();

    }, []);

    return (
        <Swiper slidesPerView={1} pagination={{ clickable: true }} autoplay>
             {banners.map(banner => (
                 <SwiperSlide key={banner.h_id}>
                    <div className={styles.slide}>
                        <div className={styles.slideImg}>
                            <img src={`http://www.itaclassificados.com.br/arquivos/destaques/${banner.h_image}`} alt={banner.h_title} width="100%" />
                        </div>
                        <div className={styles.slideContent}>
                            <h1>{banner.h_title}</h1>
                            <p>
                                {banner.h_description}
                            </p>
                        </div>
                    </div>
                 </SwiperSlide>
             ))}
        </Swiper>
    );
}