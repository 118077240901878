import styles from './styles.module.scss';

export function Footer(){
    const date = new Date();

    return (
        <p className={styles.footer}>
            {`© Copyright 2010-${date.getFullYear()} - Todos os direitos reservados`}
        </p>
    );
}